.card-container {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
  max-width: 1000px;
  /*margin-left: auto;
  margin-right: auto;*/
}

.card {
  width: 107px;
  height: 80px;
  margin: 6px;
  line-height: 1.3;
  font-weight: 700;
  font-size: 11px;
}

.card-text {
  font-size: 11px;
  color: white;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
}

.disabled-card {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: unset;
}

.disabled-card-text {
  color: rgba(0, 0, 0, 0.26);
}

/* DESKTOP VIEW */
@media screen and (min-width: 960px) {
  .card-container {
    justify-content: flex-start;
  }
  .card {
    margin: 12px;
    height: 130px;
    width: 180px;
    font-size: 13px;
  }
}
